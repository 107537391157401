export const environment = {
    production: false,
    hmr: false,
    webURL: 'https://qa.jobzmall.com',
    apiRoot: 'https://api-qa.jobzmall.com/api/',
    baseBrowserUrl: 'https://qa-app.jobzmall.com',
    baseServerUrl: 'https://qa-app.jobzmall.com',
    jobzAIUrl: 'https://jobz-ai-web.vercel.app',
    jobzAIAppUrl: 'https://jobzai-qa.jobzmall.com',
    googleApiKey: 'AIzaSyCsge6kcg6pYPmYMHP7e35u6flr81oxR5U',
    facebookAppID: '152313551938859',
    googleAppID:
        '7604090344-kupolq9o8huqaqnaa0jjcbnn23kusti5.apps.googleusercontent.com',
    microsoftAppID: '6347a941-6aa4-4ae9-b4b1-b8aa87d11790',
    zoomAppID: 'bzcUr1TnTJysd5eiFtPmQ',
    linkedInAppID: '86lk7ojjou1k3r',
    segmentWriteKey: 'ubrSpLu7zkKTnMCQL8UBrFi6Pi5VB6Bo',
    stripeKey: 'pk_test_nhoKaczKQ3BhFeJ7tco5HgHS',
    segmentDebug: true,
    intercomAppID: 'w44t9wxw',
    env: 'qa',
    botDetectApiKey: 'ymKakSqOTiCzCx2a101plTnZ',
    datadogService: 'jobzmall-universal-qa',
    datadogApplicationId: '2c68839e-1bc2-47e3-a5a2-0ecb623a6c3e',
    datadogClientToken: 'pub1731fab46e18478dd2c749fc43aa6271',
    deelClientId: 'e7b3bf46-739c-45c1-b98d-dc84f12015c0',
    deelOAuthUrl: 'https://auth-demo.letsdeel.com',
    deelUrl: 'https://demo.letsdeel.com'
};
// TODO LAUNCH: CHANGE THESE
